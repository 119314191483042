<template>
  <div class="page layout-vertical">
    <div class="margin-horizontal-large padding-vertical font-size-large layout-inflexible">
      <back-button @click="$router.back()"></back-button>
      <span> 账号管理</span>
      <ys-input
          class="input-search margin-left-large"
          placeholder="搜索账号"
          hollow
          v-model="search"
      ></ys-input>
      <ys-button class="margin-left"
                 @click="clickAddAccount"
      >
        + 创建新账号
      </ys-button>
    </div>
    <div class="layout-flexible margin-horizontal-large" style="overflow: hidden">
      <ys-infinite-scroll-view style="height: 100%"
                               @loadmore="loadMore"
      >
        <div class="list-item layout-horizontal layout-middle"
             v-for="account in accountList"
             :key="account.id"
             @click="clickAccount(account)">
          <user-avatar
              :user="account"
              class="layout-inflexible"
          ></user-avatar>
          <div class="margin-left layout-flexible">
            <div>
            <span class="font-size-medium">
              <span v-if="account.name && account.name !== account.account">{{ account.name }}</span>
              <span v-else>{{ account.account }}</span>
            </span>
              <span v-if="account.name && account.account !== account.name"
                    class="font-color-placeholder margin-left"
              >{{ account.account }}</span>
            </div>
            <div v-if="account.dietitian"
                 class="font-size-small font-color-danger"
            >营养师
            </div>
          </div>
          <div class="layout-inflexible">
            <span v-if="account.accountType === AccountType.MANAGER">管理员</span>
            <span v-else-if="account.accountType === AccountType.SUPER_MANAGER">超级管理员</span>
            <span class="font-color-light-placeholder margin-left-small"> <i class="fas fa-chevron-right"></i></span>
          </div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <account-add-dialog
        :visible.sync="accountAddDialogVisible"
    ></account-add-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInput from "@/components/wedigets/YsInput";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import AccountUtils from "@/assets/javascript/account-utils";
import UserAvatar from "@/components/user/UserAvatar";
import YsButton from "@/components/wedigets/YsButton";
import AccountAddDialog from "@/pages/system/dialog/AccountAddDialog";

export default {
  name: "AccountList",
  mixins: [httpapi],
  components: {AccountAddDialog, YsButton, UserAvatar, YsInfiniteScrollView, YsInput, BackButton},
  data() {
    return {
      /**
       * 常量
       */
      AccountType: AccountUtils.AccountType,

      /**
       * api 同步参数
       */
      busyLoadingCode: 0,

      /**
       * 搜索条件
       */
      search: '',

      /**
       * 账号列表
       */
      accountList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      /**
       * 添加账号
       */
      accountAddDialogVisible: false,


    }
  },
  watch: {
    search: {
      handler() {
        this.loadAccountList();
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('update:account', this.handleAccountUpdate);
  },
  methods: {
    clickAccount(account) {
      this.$router.push({
        name: 'account',
        query: {
          id: account.id,
        }
      })
    },
    clickAddAccount() {
      this.accountAddDialogVisible = true;
    },
    enterBusy() {
      if (this.busyLoadingCode === this.loadingCode) return false;
      this.busyLoadingCode = this.loadingCode;
      return true;
    },
    existBusy() {
      this.busyLoadingCode = 0;
      return true;
    },
    handleAccountUpdate(event) {
      this.loadAccount(event.id);
    },
    loadAccountList(pageNum = 1) {
      if (!this.enterBusy()) return;
      this.$reqGet({
        path: `/account/list/${pageNum}/${this.pageSize}/${this.search}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let accountList = pageNum === 1 ? [] : this.accountList;
            this.$appendAfter(accountList, res.data.list);
            this.accountList = accountList;
            this.pageNum = pageNum;
            this.pageSize = res.data.pageSize;
            this.totalPages = res.data.pages;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.existBusy());
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadAccountList(this.pageNum + 1);
      }
    },
    loadAccount(id) {
      this.$reqGet({
        path: `/account/${id}`
      })
          .then(res => {
            let account = res.data;
            this.$appendBefore(this.accountList, [account]);
          })
          .catch(() => this.$message.error('加载失败'));
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.input-search {
  min-width: 300px;
}

</style>