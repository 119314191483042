<template>
  <ys-dialog
      title="创建新账号"
      :visible.sync="innerVisible"
      @update:visible="updateVisible"
  >
    <div class="padding padding-large layout-vertical layout-center layout-middle" style="height: 40vh;">
      <div>
        <div class="padding-vertical">
          <span>账号头像：</span>
          <ys-image-picker
              class="image-avatar"
              :image.sync="avatar"
          >
          </ys-image-picker>
          <span class="font-color-danger margin-left" v-if="!avatar"><i class="fas fa-caret-left"></i> 选中用户头像</span>
        </div>
        <div class="padding-vertical">
          <span>账号：</span>
          <ys-input
              class="input margin-left"
              placeholder="请输入账号"
              :maxlength="32"
              v-model="account"
              @input="handleAccountInput"
              autocomplete="new-password"
          ></ys-input>
        </div>
        <div
            class="font-color-danger"
            style="margin-left: 88px;"
            v-if="account && isAccountExists"
        ><i class="fas fa-caret-up"></i> 该账号已存在！
        </div>
        <div class="padding-vertical">
          <span>密码：</span>
          <ys-input
              class="input margin-left"
              show-password
              placeholder="请输入6-20位密码"
              :maxlength="20"
              v-model="password"
          ></ys-input>
        </div>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="clickCancel">取消</ys-button>
      <ys-button class="margin-left" lighting @click="clickConfirm">确定</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import YsButton from "@/components/wedigets/YsButton";
import md5 from "js-md5";
import YsImagePicker from "@/components/wedigets/YsImagePicker";

export default {
  name: "AccountAddDialog",
  mixins: [httpapi],
  components: {YsButton, YsInput, YsImagePicker, YsDialog},
  props: {
    visible: Boolean,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 账号
       */
      account: null,

      /**
       * 密码
       */
      password: null,

      /**
       * 头像（可选）
       */
      avatar: null,

      /**
       * 账号已存在
       */
      isAccountExists: false,

      /**
       * 显示弹窗
       */
      innerVisible: false,

      /**
       * 延迟计时器
       */
      checkAccountExistsTimeoutId: null,
    }
  },
  watch: {
    visible: {
      handler(visible) {
        this.innerVisible = visible;
        this.avatar = null;
        this.account = null;
        this.password = null;
        this.isAccountExists = false;
      },
      immediate: true,
    }
  },
  methods: {
    handleAccountInput() {
      if (this.checkAccountExistsTimeoutId) {
        clearTimeout(this.checkAccountExistsTimeoutId);
      }
      this.checkAccountExistsTimeoutId = setTimeout(() => {
        this.checkAccountExists();
      }, 500);
    },
    clickCancel() {
      this.innerVisible = false;
      this.updateVisible();
    },
    clickConfirm() {
      if (!this.account) {
        this.$message.warning('请输入账号');
        return;
      }
      if (this.isAccountExists) {
        this.$message.warning('账号已存在!');
        return;
      }
      if (!this.password) {
        this.$message.warning('请输入密码');
        return;
      }
      if (this.password.length < 6) {
        this.$message.warning('请至少输入6位密码');
        return;
      }
      this.addAccount();
    },
    updateVisible() {
      this.$emit('update:visible', this.innerVisible);
    },
    checkAccountExists() {
      let account = this.account?.trim();
      if (!account) return;
      console.log('check account: ' + account);
      this.$reqGet({
        path: `/account/account_name/exists/${account}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            console.log('account: ' + res.data);
            let isAccountExists = res.data;
            this.isAccountExists = isAccountExists;
          })
          .catch(() => this.$message.error('网络错误'));
    },
    addAccount() {
      if (this.busyLoadingCode === this.loadingCode) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/account/${encodeURIComponent(this.account.trim())}/${encodeURIComponent(md5(this.password))}/${this.avatar ? this.avatar.id : ''}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.$message.success('添加成功');
            window.eventBus.$emit('update:account', {id: res.data.id});
            this.innerVisible = false;
            this.updateVisible();
          })
          .catch(() => this.$message.error('创建失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }
}
</script>

<style scoped>

.image-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.input {
  width: 228px;
}
</style>